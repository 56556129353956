import React from 'react'
import { graphql, PageProps, useStaticQuery } from 'gatsby'
import { useApi } from '~/siteApi'
import {
  Head,
  HomeHero,
  HomeAbout,
  Numbers,
  EnterpriseSlider,
  Testimonials,
  Certifications,
  Footer,
} from '~/components'
import * as st from '~/assets/styl/HomeAbout.module.styl'

const Home = ({ location }: PageProps) => {
  const { constructions: enterprises }: { constructions: Array<Enterprise> } =
    useApi(
      {
        constructions: useStaticQuery(graphql`
          query {
            allEnterprises {
              nodes {
                _id
                cover
                image
                slug
                name
                address
                district
                city
                state {
                  name
                  acronym
                }
                cep
                description
                category {
                  name
                }
                completePlan
              }
            }
          }
        `).allEnterprises.nodes,
      },
      'constructions'
    )

  return (
    <>
      <Head location={location} />
      <HomeHero />
      <HomeAbout />
      <Numbers className={st.numbers} />
      <EnterpriseSlider
        enterprises={enterprises.filter(
          ({ category: { name: category } }) => category !== 'Entregue'
        )}
        style={{ paddingBottom: 10 }}
      />
      <Testimonials style={{ marginTop: -10 }} />
      <Certifications />
      <Footer />
    </>
  )
}

export default Home
